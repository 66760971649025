//

// Search Leads

export type SearchLead = {
  id: string;
  dateCreated?: string;

  // Lead details
  name?: string;
  phoneNumber?: string;
  emailAddress?: string;
  description?: string;
  sourceConversationId?: string;

  // Client details
  clientId?: string;

  // Filters from most recent search
  mostRecentHomeSearch?: HomeSearch;
  homeSearches?: HomeSearch[];

  // Delivery details
  deliveryStatus?: string;
  deliveryMethod?: string;
  deliveryDestination?: string;
  deliveryDate?: string;

  // Manual details
  manuallyCreated?: boolean;
  ownerId?: String;
};

//

// HomeSearch Clients and configs

export type HomeSearch = {
  id: string;

  // UI details
  title: String;
  description: String;

  // Start and finish times
  dateCreated?: string;
  dateChatCompleted?: string;

  // Client and lead refernces
  clientId?: string;
  searchLeadId?: string;

  // Conversation details
  chatConversationId?: string;
  conversationMessages?: ChatConversationMessage[];

  // Search Results
  homeSearchResults?: HomeSearchResult[];
  newResultsCount?: number;

  // Filters
  bedroomsMinMax?: string[];
  bathroomsMinMax?: string[];
  priceMinMax?: string[];
  locations?: string[];
  features?: string[];
  buyer_type?: string;
  mailing_list?: boolean;
  finance_approved?: boolean;

  // Misc
  browserSnapShot?: string;
  zeroWaste?: boolean;

  // Calculated
  documentCount?: number;
};

export type HomeSearchClient = {
  id: string;
  dateCreated?: string;

  pineconeIndex?: string;
  locationBias?: string;
  locationRestriction?: string;
  propertyType?: string;
  infoExtractPrompt?: string;

  // Config
  homeSearchClientConfig?: HomeSearchClientConfig;
};

export type ChatGptConversation = {
  id: string;

  // info
  dateCreated?: string;
  guideType?: string;
  title?: string;

  // user
  ownerId?: string;
  owner?: User;

  // fub details
  fubContactId?: string;
  fubContext?: string;

  // Prompts and history
  conversationHistory?: ChatConversationMessage[];
};

export type ChatConversationMessage = {
  id: string;

  // info
  conversationIndex?: number;
  role?: string;
  content?: string;
  userVisible?: boolean;

  // conversation link
  chatGptConversationId?: string;
};

export type HomeSearchClientConfig = {
  id: string;
  dateCreated?: string;

  // Client details
  clientId?: string;
  clientName?: string;
  appId?: string;

  // UI details
  surfaceUrl?: string;
  showChatAfterXSeconds?: number;
  privacyPolicyText?: string;
  termsAndConditionsText?: string;
  resultsPerSearch?: number;
  imageQualitySuffix?: string;

  // Chatbot details
  guidedChatId?: string;
  firstMessageText?: string;

  // Data feed details
  dataSourceUrl?: string;

  // Lead Delivery Settings
  leadDeliveryMethod?: string;
  leadDeliveryFrequency?: string;
  leadDeliveryDestination?: string;

  leadDeliveryIntegrationName?: string;
  leadDeliveryIntegrationAccessKey?: string;
  leadDeliveryIntegrationFieldMappings?: string[];

  useLeadDelivery?: boolean;
  useEmailAttachments?: boolean;

  suppressLeadCapture?: boolean;

  // Branding settings
  useOwnBranding?: boolean;

  brandLogoUrl?: string;
  brandLogoAspectRatio?: number;
  brandLogoHasWhiteBackground?: string;
  brandTextColor?: string;
  brandPrimaryColor?: string;
  brandHighlightColor?: string;
  brandCtaButtonColor?: string;

  hideMatchPercentages?: boolean;

  resultsScrollDirection?: string;

  // Timezone
  timezone?: string;

  //  Onboarding details
  onboardingStatus?: string;
  hasCopiedScript?: string;
};

//

// Search Results

export type HomeSearchResult = {
  id: string;
  externalId?: string;

  homeSearchId?: string;

  url?: string;
  bed?: number;
  bath?: number;
  listPrice?: number;
  priceMethod?: string;
  score?: number;
  inLocation?: boolean;

  address?: string;
  media?: string;

  fromOriginalSearch?: boolean;

  // User interactions
  linkClicked?: string[];
};

//

//

// Enums

export enum HomeSearchLocation {
  ListView = "list-view",
  ChatView = "chat-view",
  ChatLauncher = "chat-launcher",
  SearchResults = "search-results",
  ContactDetailsForm = "contact-details-form",
  HomeSearchCreation = "home-search-creation",
}

// Event types
export enum ListingEventType {
  Keyboard = "keyboard_input",
  Click = "click",
  Upload = "upload",
  Prompt = "prompt",

  // Product Tours
  TourShow = "tour_show",
  TourClick = "tour_click",
  TourAuto = "tour_auto",
  TourClose = "tour_close",
  TourComplete = "tour_complete",

  // HomeSearch
  Background = "background",
}

//

//

// Titles as enums

export enum KeyboardEventTitle {
  // Address
  AddressSearch = "Address search",

  // Details
  FloorAreaInput = "Floor area input",
  LandAreaInput = "Land area input",

  // Features
  ManuallyAddFeature = "Manually add feature",

  // Generations
  TitleManualEdit = "Title manually edited",
  DescriptionManualEdit = "Description manually edited",

  // Fine tune
  OpeningManualEdit = "Opening manually edited",
  FeaturesManualEdit = "Features manually edited",
  BedroomsManualEdit = "Bedrooms manually edited",
  KitchenManualEdit = "Kitchen manually edited",
  OutsideManualEditk = "Outside manually edited",
  LocationManualEdit = "Location manually edited",
  ContactManualEdit = "Contact manually edited",

  // Feedback
  FeedbackCommentEntered = "Feedback comment entered",
}

export enum ClickEventTitle {
  // Address section
  AddressSelect = "Address select",
  NextButton = "Next button clicked",

  // Photos
  RemoveImageButton = "Remove image button clicked",

  // Details
  BedroomsButton = "Bedrooms button clicked",
  BathroomsButton = "Bathrooms button clicked",
  PropertyTypeButton = "Property type button clicked",
  TitleTypeButton = "Title type button clicked",

  // Feature
  FeatureButton = "Feature button clicked",
  RemoveFeatureButton = "Remove feature button clicked",
  UseBulletPointsButton = "Use bullet points button clicked",

  // Generations
  RegenerateTitleButton = "Regenerate Title button clicked",
  RegenerateDescriptionButton = "Regenerate Description button clicked",
  ViewPreviousTitlesButton = "View previous titles button clicked",
  ViewPreviousDescriptionsButton = "View previous descriptions button clicked",
  CopyTitleButton = "Copy Title button clicked",
  CopyDescriptionButton = "Copy Description button clicked",

  // Fine tuning
  RegenerateOpeningClick = "Regenerate Opening clicked",
  RegenerateFeaturesClick = "Regenerate Features clicked",
  RegenerateBedroomsClick = "Regenerate Bedrooms clicked",
  RegenerateKitchenClick = "Regenerate Kitchen clicked",
  RegenerateOutsideClick = "Regenerate Outside clicked",
  RegenerateLocationClick = "Regenerate Location clicked",
  RegenerateContactClick = "Regenerate Contact clicked",

  // Vertical Menu
  VerticalMenuClick = "Vertical menu clicked",

  // Hozizontal Menu
  HorizontalMenuClick = "Horizontal menu clicked",

  // Manage listing status
  MarkAsDone = "Mark as done button clicked",
  SetFilesLive = "Set Files link Live button clicked",
  RevertToDraft = "Revert to draft button clicked",

  // Feedback modal
  SkipFeedbackModal = "Skip feedback modal button clicked",
  SelectFeedbackRating = "Feedback rating button clicked",
  ChangeFeedbackRating = "Feedback rating changed",
  SubmitFeedbackModal = "Submit feedback modal button clicked",
  FeedbackModalBackingClick = "Feedback modal backing clicked",

  // Helper videos
  HelperVideoClick = "Helper video clicked",
}

export enum UploadEventTitle {
  PhotoUpload = "Photo upload",
}

export enum PromptEventTitle {
  // Feedback modal
  FeedbackModalShown = "Feedback modal shown",
}

export enum TourEventTitle {
  // Show
  ShowTour = "Tour shown to user",

  // Click
  BackClick = "Tour Back button clicked",
  NextClick = "Tour Next button clicked",
  CloseClick = "Tour Close button clicked",

  // Auto
  AutoBack = "Tour Back automatically actioned",
  AutoNext = "Tour Next automatically actioned",
  AutoClose = "Tour Close automatically actioned",

  // Extra
  ScrollThenNext = "Tour Scroll then Next actioned",
  PauseThenNext = "Tour Pause then Next actioned",

  // Extra
  CompleteClick = "Tour completed!",
}

export enum CountryLocale {
  US = "US",
  GB = "GB",
  AU = "AU",
  NZ = "NZ",
  CA = "CA",
  CR = "CR",
  UK = "UK",

  // Other option for opening up full list
  OTHER = "OTHER",
}

export type CreateListingEventTitle =
  | KeyboardEventTitle
  | ClickEventTitle
  | UploadEventTitle
  | PromptEventTitle
  | TourEventTitle;

//

//

// Listing

export type Listing = {
  __typename?: "Listing";
  id: string;
  slackThreadId?: string;

  addressText?: string;

  bathrooms?: number;
  bedrooms?: number;
  billingStatus?: string;

  city?: string;
  country?: CountryLocale;

  dateCreated?: string;
  firstCompletionDate?: string;

  description?: string;

  readyForGeneration?: string;

  generatedDescriptions?: string[];
  generatedDescriptionsCount?: number;

  generatedSignboardDescriptions?: string[];
  signboardCharacterLimit?: number;
  useSignboardBulletPoints?: string;

  generatedPressDescriptions?: string[];
  pressCharacterLimit?: number;

  generatedSocialDescriptions?: string[];
  generatedScriptDescriptions?: string[];

  descriptionEditedByUser?: boolean;

  generatedOpeningSections?: string[];
  generatedFeaturesSections?: string[];
  generatedKitchenSections?: string[];
  generatedBedroomsSections?: string[];
  generatedOutsideSections?: string[];
  generatedContactSections?: string[];
  generatedSectionsCount?: number;

  sectionOrder?: string[];
  sectionTemperatures?: number[];

  // Non for sale listing types
  useVacationRentals?: string;
  listingType?: string;

  // Chat Generations
  chatGenerations?: ChatGeneration[];

  buildStyle?: string;
  listingTargetAudience?: string;

  floorArea?: number;

  imageUrls?: string[];
  imageUrlsMedium?: string[];
  imageUrlsSmall?: string[];

  lat?: number;
  lng?: number;

  linzTitle?: any;

  ownerId?: string;
  propertyType?: string;
  priceDisplayedAs?: string;
  detailedPriceDisplayedAs?: string;
  spiritAnimal?: string;
  spiritAnimalUpper?: string;
  status?: string;
  streetName?: string;
  streetNumber?: string;
  suburb?: string;

  title?: string;
  generatedTitles?: string[];
  generatedTitlesCount?: number;

  user?: User;

  nearbyPlaces?: any[];
  linzTitleId?: string;
  linzParcelId?: string;

  titleType?: string;
  landParcelArea?: string;

  tradeMeListingId?: string;

  imageCategorisations?: any[];
  listingFeedback?: any[];

  externalImageUrls?: [string];

  // Using chat generated desciprtion
  useFullChatDescription?: string;

  // Embeddings
  embeddingsCompleted?: string;

  // Search scoring
  searchScore?: number;

  // Ratings
  epcRating?: string;
  epcRatingPotential?: string;

  enviroImpactRating?: string;
  enviroImpactRatingPotential?: string;

  // Vault Integration
  vaultListingId?: string;
  integrationSource?: string;
  integrationReference?: string;

  // Feature flags
  useBulletPoints?: string;

  // Files
  filesShortCode?: string;
  filesPageViews?: number;
  filesWebsiteStatus?: string;
  filesPreviewCode?: string;

  liveDocuments?: any[];
  filesUsers?: any[];

  // TaggedImages
  taggedImages?: any[];
};

//

//

// Chat Generations

export type GuidedChatConversation = {
  id: string;

  // datecreated
  dateCreated?: string;

  // Info
  guideType?: string;
  guideTitle?: string;
  guideSubtitle?: string;

  guideSection?: string;
  surfaceLocation?: string;

  live?: string;
  selectedModelId?: string;

  // Prompts
  systemPrompt?: string;
  guidedUserPrompts?: string[];
  guidedAssistantPrompts?: string[];

  // Generations
  initialGeneration?: string;

  // Test conversations
  testConversationIds?: string[];
};

export type ChatGeneration = {
  id: string;
  dateCreated?: string;
  listingId?: string;
  guidedChatConversationId?: string;

  generatedDescriptions?: string[];
  chatConversationIds?: string[];

  guidedChatConversation?: GuidedChatConversation;
};

export type User = {
  __typename?: "User";
  id: string;
  userType?: string;
  signUpSlackThreadId?: string;

  // contact
  emailAddress: string;
  firstName?: string;
  companyName?: string;
  phoneNumber?: string;

  // buyflow
  selectedBuyFlowProduct?: string;

  // veriification
  verificationCod?: number;
  verified?: boolean;

  resetPasswordVerificationCode?: string;
  resetPasswordVerificationCodeExpiryDate?: string;

  resetPasswordShortLink?: string;
  resetPasswordFullLink?: string;

  // Billing
  accountStatus?: string;
  trialStartDate?: string;
  trialEndDate?: string;
  xeroContactId?: string;
  stripeCustomerId?: string;

  // Stripe Billing
  stripeSubscriptionId?: string;
  stripeSubscription?: any;
  promptMoveToStripeBilling?: String;

  // Rebates and special billing requirements
  requiresHarcourtsRebate?: string;

  // Referral codes
  referralCode?: string;

  // Location
  city?: string;
  referral?: string;
  acceptTerms?: string;

  // Intercom
  intercomUserId?: string;

  // HubSpot
  hubSpotContactId?: string;

  // features
  showLoadPhotosFromTradeMe?: string;
  showLoadPhotosFromDiakrit?: string;
  showPerfectExamples?: string;
  showInternalNotes?: string;
  showListingProductTour?: string;
  showHelperVideos?: string;

  useCustomSectionOrder?: string;
  useRoomBasedFeatures?: string;
  useImageDescriptions?: string;
  useFeatureSupercharger?: string;
  useOutreachWriter?: string;
  useWriteStoredData?: string;
  useDetailedLocationDetails?: string;
  useVacationRentals?: string;
  useFullListingRewrite?: string;
  useFullChatDescription?: string;

  // User integrations
  fubApiKey?: string;

  // API
  apiKey?: string;
  encryptedApiKey?: string;
  apiKeyLength?: number;
  hasSeenApiKey?: string;
  obscuredApiKey?: string;

  // Listing defaults
  listingCountry?: string;
  customSectionOrder?: string[];

  // standard sale details
  usualSaleMethod?: string;
  saleDateInDays?: string;
  usualSaleCloseTime?: string;
  usualAuctionLocation?: string;

  // user tracking
  lastLoginDate?: string;
  numberOfLogins?: number;

  // Agency related
  agencyId?: string;
  agencyBranchId?: string;
  isAgencyAdmin?: boolean;
  isAgencyBranchAdmin?: boolean;
  tradeMeAgentUrl?: string;
  branchActiveStatus?: string;

  // onboarding
  welcomePasswordTicketUrl?: string;
  welcomePasswordTicketIssueDate?: string;
  hasReceivedWelcomeEmail?: string;

  // branding
  profilePhotoUrl?: string;
  agencyPhotoUrl?: string;
  shortAgentDescription?: string;
  cardBackgroundColour?: string;
  profilePhotoBorderColour?: string;
  agencyWebsiteUrl?: string;

  hasSeenFilesIntro?: string;
  hasSeenFilesOnboarding?: string;

  // Linked agency
  agency?: any;
  agencyBranch?: any;

  // Subscription
  subscription?: any;

  // Product Tours
  hasSeenListingTour?: string;

  // Billing
  paymentMethod?: any;

  // Generated client side
  confirmedPaymentStatus?: string;
};

//

//

// Helper functions

export function GetRegenerateClickTitleTypeBySection(sectionName: string) {
  switch (sectionName) {
    case "opening":
      return ClickEventTitle.RegenerateOpeningClick;
    case "features":
      return ClickEventTitle.RegenerateFeaturesClick;
    case "bedrooms":
      return ClickEventTitle.RegenerateBedroomsClick;
    case "kitchen":
      return ClickEventTitle.RegenerateKitchenClick;
    case "outside":
      return ClickEventTitle.RegenerateOutsideClick;
    case "location":
      return ClickEventTitle.RegenerateLocationClick;
    case "contact":
      return ClickEventTitle.RegenerateContactClick;

    default:
      return ClickEventTitle.RegenerateOpeningClick;
  }
}

export function GetManualEditTitleTypeBySection(sectionName: string) {
  const sectionNameToLower = sectionName.toLowerCase();

  switch (sectionNameToLower) {
    case "generatedOpeningSections":
      return KeyboardEventTitle.OpeningManualEdit;
    case "generatedfeaturessections":
      return KeyboardEventTitle.FeaturesManualEdit;
    case "generatedbedroomssections":
      return KeyboardEventTitle.BedroomsManualEdit;
    case "generatedkitchensections":
      return KeyboardEventTitle.KitchenManualEdit;
    case "generatedoutsidesections":
      return KeyboardEventTitle.OutsideManualEditk;
    case "generatedlocationsections":
      return KeyboardEventTitle.LocationManualEdit;
    case "generatedcontactsections":
      return KeyboardEventTitle.ContactManualEdit;

    default:
      return KeyboardEventTitle.OpeningManualEdit;
  }
}

//

// Arrayus

export const CONVERSATION_FINISHERS = [
  "alright, alright, alright",
  "alright alright alright",
  "alright! alright! alright!",
  "[DONE]",
  "give me a moment",
  "wait a moment",
  "get back to you as soon",
  "ll now begin the search",
  "thanks for your patience",
  "thank you for your patience",
  "give me some time to",
  "hold on while I find",
  "ll now start searching",
  "ll now start the search", // Could be removed in future
  "ll now start",
];
