import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import AppRouter from "./appRouter";
import { inject } from "@vercel/analytics";
import reportWebVitals from "./reportWebVitals";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

// Providers
import StylesProvider from "@mui/styles/StylesProvider";
import { AppViewProvider } from "providers/appViewProvider";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

// Theme
import { theme as homeSearchTheme } from "./style/homeSearchTheme";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
    spacing: number;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    spacing: number;
  }
}

// Analytics
inject();

// QueryClient

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const queryCacheMaxAge = 1000 * 60 * 60 * 6; // 6 hours

const queryClient = new QueryClient({});

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <StylesProvider>
      <AppViewProvider>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister, maxAge: queryCacheMaxAge }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={homeSearchTheme}>
              <AppRouter />
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactQueryDevtools />
        </PersistQueryClientProvider>
      </AppViewProvider>
    </StylesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
