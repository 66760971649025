import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  zIndex: {
    modal: 2000,
    snackbar: 2000,
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#6f6f6f",
    },
    info: {
      main: "#084718",
    },
    warning: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: "Inter",
    h1: {
      fontSize: "1.6em",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.3em",
    },
    h3: {
      fontSize: "1.25em",
    },
    h5: {
      fontSize: "1.4em",
      fontWeight: "normal",
      textAlign: "left",
    },
    h6: {
      textAlign: "left",
    },
    body1: {
      textAlign: "left",
    },
    button: {
      textTransform: "none",
      transition: "100ms",

      ":hover": {
        opacity: 0.6,
      },
      fontWeight: "bold",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: 10,
});
