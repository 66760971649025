import { Context, createContext, useReducer, Dispatch } from "react";

// Types
import { HomeSearchLocation } from "types";

//

// Placeholder state
export const PLACEHOLDER_STATE: AppViewState = {
  // App State
  currentAppView: HomeSearchLocation.ListView,

  // HomeSearch
  currentHomeSearchId: "",

  // ChatConversation
  currentChatConversationId: "",

  // SearchLead
  currentSearchLeadId: "",

  // Lead detasil form
  showContactDetailsForm: false,
  listingIdToOpen: "",
};

//

// Types

export interface AppViewState {
  // App State
  currentAppView: HomeSearchLocation;

  // HomeSearch
  currentHomeSearchId: string;

  // ChatConversation
  currentChatConversationId: string;

  // SearchLead
  currentSearchLeadId: string;

  showContactDetailsForm: boolean;
  listingIdToOpen: string;
}

interface ContextShape {
  mode: AppViewState;
  dispatch: Dispatch<any>;
}

interface ActionPayload {
  type: AppViewActionType;
  payload: string;
}

export enum AppViewActionType {
  //

  // App View
  SET_APP_VIEW = "SET_APP_VIEW",

  //

  // HomeSearch
  SET_HOMESEARCH_ID = "SET_HOMESEARCH_ID",
  OPEN_HOMESEARCH = "OPEN_HOMESEARCH",

  //

  // ChatConversation
  SET_CHATCONVERSATION_ID = "SET_CHATCONVERSATION_ID",

  //

  // SearchLead
  SET_SEARCHLEAD_ID = "SET_SEARCHLEAD_ID",

  //

  // Lead form
  SET_SHOW_CONTACT_DETAILS_FORM = "SET_SHOW_CONTACT_DETAILS_FORM",
  SET_LISTING_ID_TO_OPEN = "SET_LISTING_ID_TO_OPEN",
}

function reducer(state: any, action: ActionPayload) {
  const { type, payload } = action;

  switch (type) {
    //

    // Cases for updating
    case AppViewActionType.SET_APP_VIEW: {
      return {
        ...state,
        currentAppView: payload,
      };
    }

    case AppViewActionType.SET_HOMESEARCH_ID: {
      return {
        ...state,
        currentHomeSearchId: payload,
      };
    }

    case AppViewActionType.OPEN_HOMESEARCH: {
      return {
        ...state,
        currentAppView: HomeSearchLocation.ChatView,
        currentHomeSearchId: payload,
      };
    }

    case AppViewActionType.SET_CHATCONVERSATION_ID: {
      return {
        ...state,
        currentChatConversationId: payload,
      };
    }

    case AppViewActionType.SET_SEARCHLEAD_ID: {
      return {
        ...state,
        currentSearchLeadId: payload,
      };
    }

    //

    // Lead form
    case AppViewActionType.SET_SHOW_CONTACT_DETAILS_FORM: {
      return {
        ...state,
        showContactDetailsForm: payload,
      };
    }

    case AppViewActionType.SET_LISTING_ID_TO_OPEN: {
      return {
        ...state,
        listingIdToOpen: payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}

const AppViewContext: Context<ContextShape> = createContext({} as ContextShape);

const AppViewProvider = ({ children }: any) => {
  const [mode, dispatch] = useReducer(reducer, PLACEHOLDER_STATE);

  return (
    <AppViewContext.Provider
      value={{
        mode,
        dispatch,
      }}
    >
      {children}
    </AppViewContext.Provider>
  );
};

export { AppViewProvider, AppViewContext };
