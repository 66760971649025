import { lazy, Suspense } from "react";
import { Grid, styled } from "@mui/material";
import { Routes, Route, BrowserRouter } from "react-router-dom";

//

// Lazy loaded components
const HomeSearchSearchApp = lazy(
  () => import("app/homeSearch/container/homeSearchApp")
);
const HomeSearchSearchLauncher = lazy(
  () => import("app/homeSearch/launcher/homeSearchLauncher")
);
const HomeSearchIndex = lazy(() => import("app/homesearchRoot"));

const PageLoadingGrid = styled(Grid)(() => ({
  background: "#7a7a7a",
  height: "100vh",
  width: "100%",
}));

const PageLoading = () => {
  return <PageLoadingGrid />;
};

function Directory() {
  return (
    <Routes>
      <Route path="/search-widget" element={<HomeSearchSearchApp />} />

      <Route path="/search-launcher" element={<HomeSearchSearchLauncher />} />

      <Route path="/" element={<HomeSearchIndex />} />
    </Routes>
  );
}

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoading />}>{Directory()}</Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
